.container-crd {
  /* background-image: linear-gradient(to right top, #100D40, #1a1b4e, #2a2c6a, #3a3d84, #4a4e9e); */
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  /* padding: 25px 30px; */
  color: #ffffff;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 10px;
}

.container-crd:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-crd h1 {
  color: #ffffff;
  text-align: center; 
  
}

.container-crd p {
  text-align: center;
}

.container-crd::after {
  content: '';
  background: #181818;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  clip-path: circle(20% at 0% 0%);
  transition: all 0.3s ease-in;
}

.content-crd {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in;
}

.content-crd h1 {
  font-size: 25px;
  color: #FFD333;
}

.container-crd:hover::after {
  clip-path: circle(100%);
}

.container-crd:hover .content-crd h1 {
  color: white;
}

.container-crd:hover .content-crd p {
  color: #FFD333;
}

/* Add styles for the icon */
.container-crd .icon {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 20px; /* Adjust the size of the icon */
  z-index: 2; /* Ensure the icon is above the background */
  color: #FFD333; /* Ensure the icon color matches the text color */
  transition: transform 0.3s ease-in-out; /* Smooth scaling transition */
}

/* Scale up the icon on hover */
.container-crd:hover .icon {
  transform: scale(1.4); /* Scale up the icon to 120% */
}
