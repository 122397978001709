@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* modal  */
.full-width-modal {
  max-width: 100vw;
  margin: 0;
}
.table-responsive-custom {
  overflow-x: auto;
}

.table-bordered-custom {
  width: 100%;
  border-collapse: collapse;
}

.table-bordered-custom th,
.table-bordered-custom td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  font-size: 14px;
}
.modalheadingmembers {
  text-align: center;
  font-size: 18px;
  text-decoration: underline;
}

.table-bordered-custom th {
  width: 150px;
  font-weight: bold;
}
.formMain {
  position: relative;
  height: 100vh;
  align-items: center;
}
.formMainImg {
  width: 100%;
  height: 100vh;
  /* opacity: 0.1; */
  position: absolute;
  z-index: -1;
  filter: brightness(50%);
  object-fit: cover;
}
.formForm {
  text-align: center;
}
.formMainDiv {
  border: 1px solid white;
  /* padding: 30px; */
  border-radius: 10px;
  text-align: center;
  background-color: #f0f0f0;
  margin-top: 30px;
}
.LoginHeaderMain {
  margin: -24px 16px 8px;
  padding: 16px;
  text-align: center;
  opacity: 1;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(52, 71, 103);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.LoginHeaderText {
  margin: 8px 0px 0px;
  font-size: 1.5rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

/* .TableHeaderMain {
  margin: -24px 16px 8px;
  padding: 16px;
  text-align: center;
  opacity: 1;
  background: #100D40;
  color: rgb(52, 71, 103);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
} */
.TableHeaderText {
  /* margin: 8px 0px 0px; */
  font-size: 1.3rem;
  /* line-height: 1.375; */
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  /* letter-spacing: 0.00735em; */
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: #202020;
  font-weight: 600;
  margin: 0;
  text-align: center;
}
/* .TableHeaderMainCon{
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
} */
.formH1 {
  color: rgb(0, 0, 0);
  padding: 10px;
}
.formInput {
  background-color: transparent;
  border: none;
  /* border-bottom: 1px solid rgb(0, 0, 0); */
  /* margin: 20px; */
  padding: 8px 8px 8px 5px;
  outline: none;
  display: flex;
  width: 83%;
}
.formInput::placeholder {
  color: rgb(0, 0, 0);
}
.formButton {
  width: 100%;
  border: none;
  margin: 10px 10px 10px 0;
  padding: 10px 0;
  border-radius: 5px;
  font-weight: bold;
}

.formIcon {
  color: rgb(0, 0, 0);
  padding: 10px 0 10px;
  text-align: end;
}
/* .formError{
    color: rgb(235, 104, 104);
} */

/* =======dashboard========= */
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 100%;
}

.search-input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 280px;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 7px 40px 7px 15px; /* Adjust padding to make room for the icon */
  font-size: 16px;
  border: 1px solid lightgray;
  background: transparent;
  border-radius: 25px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: border-color 0.3s ease;
}

.search-input::placeholder {
  font-weight: 400;
  color: #a8a29e;
}

.search-input:focus {
  border-color: #707070;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
  color: #d4d4d8;
}
.dashboard-card {
  color: #fff;
  font-family: "Poppins";
  height: 160px;
  cursor: pointer;
}

.bg-c-blue {
  background-image: linear-gradient(
    to right top,
    #181818,
    #2e2222,
    #473333,
    #604545,
    #795858
  );
}

.bg-c-green {
  background-image: linear-gradient(
    to right top,
    #181818,
    #2e2222,
    #473333,
    #604545,
    #795858
  );
}

.bg-c-yellow {
  background-image: linear-gradient(
    to right top,
    #181818,
    #2e2222,
    #473333,
    #604545,
    #795858
  );
}

.bg-c-pink {
  background-image: linear-gradient(
    to right top,
    #181818,
    #2e2222,
    #473333,
    #604545,
    #795858
  );
}

.dashboardcard {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dashboardcard .dashboardCard-block {
  padding: 10px 10px;
  text-align: center;
}
.dashboardcard .dashboardCard-image {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5px;
}
.dashboardCard-icon {
  background-color: #ffffff50;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  /* font-size: 14px; */
  margin: 1px 1px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
/* ============ dashboard header============= */
.custom-navbar {
  background: #181818 !important;

  /* overflow-x: hidden; */
  position: static;
  width: 100vw;
}

.custom-navbar .navbar-brand {
  /* font-size: 32px; */
  font-weight: 600;
  color: #f5f5f5;
  /* background-color: #8985ca; */
}

.custom-navbar .navbar-brand > span {
  opacity: 0.4;
}
.custom-navbar .container-fluid {
  background-color: rgba(205, 205, 223, 0.459) 0px 7px 29px 0px;

  width: 100vw;
  border-radius: "130px";
}

.custom-navbar .navbar-toggler {
  border-color: transparent;
}

.menu-icon {
  color: #f5f5f5;
  display: inline-block;
  width: 2em;
  height: 1.8em;

  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.custom-navbar .navbar-toggler:active,
.custom-navbar .navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.active {
  border-bottom: 3px solid var(--secondarycolor--);
}

@media (min-width: 992px) {
  .custom-navbar .custom-navbar-nav li {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.custom-navbar .custom-navbar-nav li a {
  font-weight: 500;
  color: red !important;
  font-size: 16px;
  /* opacity: .5; */
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
}

@media (min-width: 768px) {
  .custom-navbar .custom-navbar-nav li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 8px;
    background: #f57d21;
    height: 3px;
    opacity: 1;
    visibility: visible;
    width: 0;
    -webkit-transition: 0.15s all ease-out;
    -o-transition: 0.15s all ease-out;
    transition: 0.15s all ease-out;
  }
}

.custom-navbar .custom-navbar-nav li a:hover {
  opacity: 1;
}

.custom-navbar .custom-navbar-nav li a:hover:before {
  width: calc(100% - 16px);
}

.custom-navbar .custom-navbar-nav li.active a {
  opacity: 1;
}

.custom-navbar .custom-navbar-nav li.active a:before {
  width: calc(100% - 16px);
}

.custom-navbar .custom-navbar-cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width: 768px) {
  .custom-navbar .custom-navbar-cta {
    margin-left: 40px !important;
  }
}

.custom-navbar .custom-navbar-cta li {
  margin-left: 0px;
  margin-right: 0px;
}

.custom-navbar .custom-navbar-cta li:first-child {
  margin-right: 20px;
}
.btn-logout {
  background-color: #323232;
  color: #f5f5f5;
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  gap: 5px;
}
.btn-logout:hover {
  background-color: #404040;
  color: #f5f5f5;
}

/* ============ dashboard header end============= */

/* ============== Sidebar =================== */
.btn-sidebarLogout {
  width: 100%;
  background-color: #2ed8b6;
  color: white;
  position: static;
  bottom: 0;
}
.btn-sidebarLogout:hover {
  background-color: white;
  color: #2ed8b6;
  border: 1px solid #2ed8b6;
}
#sidebar {
  /* overflow-y: scroll;
    scrollbar-width: none; */
  grid-area: sidebar;
  height: 100vh;
  flex: 0 0 30%;
  min-width: 180px;
  color: #e5e5e5;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  scrollbar-width: none; /* Hide scrollbar initially */
  transition: scrollbar-width 0.5s ease;
  /* margin:15px; */
  background-color: #181818;
  /* border-radius: 10px; */
}
@media (max-width: 800px) {
  #sidebar {
    height: 100vh;
  }
}

/* .sidebar-list::-webkit-scrollbar {
    width: 13px;
   
     
  }
  
  .sidebar-list::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: rgb(163, 13, 13); 
  }
  
  .sidebar-list::-webkit-scrollbar-track {
    background-color: rgba(145, 23, 23, 0.712); 
  } */

.profile-sidebar img {
  align-items: center;
  width: 65px;
}

.sidebar-title {
  justify-content: center;
  align-items: center;
  height: 18%;
  padding: 20px 0px;
  width: 100%;
  color: #e5e5e5;
  cursor: pointer;
  /* position: relative;  */
}

.sidebar-brand {
  font-size: 14px;
  font-weight: 400;
  /* position: absolute; */
  color: #ffffff;
  margin-top: 5px;
}

.logout {
  height: 7%;
  width: 100%;
  color: #e4e4e4;
  font-size: 15px;
  font-weight: 400;
  padding: 6px;
  cursor: pointer;
}

.logout:hover {
  background-color: #212121;
}

.logout2 {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  width: 100%;
}

.sidebar-list {
  padding: 0 5px;
  height: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border: 2px solid red; */
  overflow-y: scroll;
  scrollbar-width: none;
  line-height: normal;
}

.sidebar-list:hover {
  overflow-y: auto;
  scrollbar-width: thin; /* Show thin scrollbar */
}

.sidebar-list-item {
  padding: 0px 6px;
  font-size: 14px;
  color: #e4e4e4;
  width: 100%;
}
.nested-list-master {
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /* margin-left: -10px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 1px;
  background: #303030;
  border-radius: 10px;
}
.nested-list-master-item {
  margin-top: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.nested-list-master-item:hover {
  background-color: #202020;
  color: #e5e5e5;
  padding: 8px;
  border-radius: 5px;
}
.sidebar-list-item p {
  font-size: 12px;
  font-weight: 500;
  margin-left: -20px;
  color: rgb(148, 147, 147);
  border: 2px dotted yellow;
}
.sidebar-list-item .icon {
  /* margin-left: -10px; */
  font-size: 20px;
}

.sidebar-list-item:hover {
  cursor: pointer;
}
/* .img-radius {
	border-radius: 50%;
} */
@media screen and (max-width: 992px) {
  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
@media (min-width: 1000px) {
  .menu-icon {
    /* display: none; */
  }
}

.sidebar-list-item > span {
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  font-weight: 400;
  flex-direction: column;
}
.sidebar-list-item > span:hover {
  background-color: #282828;
  color: #f5f5f5;
  margin: 0;
  border-radius: 5px;
}
.sidebar-list-item.active > span {
  background-color: #f5f5f5;
  color: #202020;
  font-weight: 600;
}

.nested-list-master-item.active {
  background-color: white;
  color: #100d40;
  font-weight: 600;
}

.sidebar-responsive {
  /* display: inline !important; */
  /* position: absolute; */

  z-index: 12 !important;
}
.sidebar-responsive-set {
  display: none;
}

@media (max-width: 1000px) {
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
  }
  .closeIconSideNav {
    position: absolute;
    right: 0;
    color: white;
    margin: 5px;
  }
}

@media (min-width: 1000px) {
  .closeIconSideNav {
    display: none;
  }
}

.dashboard-over {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

/* =============== End Sidebar ================= */

/* =========login credentials=========== */
.btn-addCredential {
  background-color: #181818;
  color: #e5e5e5;
  display: flex;
  align-items: center;
  gap: 4px;
}
.btn-addCredential:hover {
  background-color: #323232;
  color: #fafafa;
  border: 1px solid #100d40;
}

.selectRole {
  width: 100%;
}

.selectRole,
selectTag {
  border: none;
}

@media (min-width: 700px) {
  .selectRole {
    width: 40%;
  }
}

/* =========== model ========= */
.memberWidth {
  width: 150px;
}
@media (min-width: 700px) {
  .memberWidth {
    width: 170px;
  }
}
.member-photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.member-photo img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

/*============== tables ============ */

.table-list {
  border-radius: 10px;
  cursor: pointer;
  overflow-x: scroll;
  text-align: center;
  width: 100%;
}
.table-list tr,
th,
td {
  padding: 5px;
}

@media (min-width: 700px) and (max-width: 1000px) {
  .table-list tr,
  th,
  td {
    padding: 10px 20px;
  }
}
@media (min-width: 1000px) {
  .table-list tr,
  th,
  td {
    padding: 10px 40px;
  }
}

.table-list th {
  background-color: #303030;
  color: #fafafa;
  font-weight: 400;
}
.table-list tr:hover {
  background-color: rgb(235, 234, 234);
}
.add-martcategory-title {
  /* border-bottom: 3px solid #4099ff; */
  padding-bottom: 5px;
  padding: 10px;
  color: #100d40;
  text-align: center;
  font-weight: 400;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #00000030;
}

/*========= Sidebar.css ==========*/

.nested-dropdown {
  border-color: #4099ff;
  border-width: 1px;
  border-style: dashed;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  padding: 10px 0;
  color: white;
  border-radius: 10px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.sidebarhover:hover {
  background-color: #282828;
  border-radius: 5px;
}

.sidebarhover.active {
  background-color: #f5f5f5;
  color: #202020;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.label,
.colon,
.value {
  flex: 1;
  text-align: left;
}

.colon {
  text-align: center;
}

.custom-pagination .page-link {
  color: #181818;
}

.custom-pagination .page-link:hover {
  background-color: #232323;
  color: rgb(255, 255, 255);
}

.custom-pagination .page-item.active .page-link {
  background-color: #303030;
  color: white;
  border: none;
  border-left: 2px solid #383838;
  border-right: 2px solid #383838;
}

/*=========== printing table css =============== */

.MemberDetailPrintTable {
  border: 2px solid #000000;
  width: 100%;
}
.MemberDetailPrintTable th,
td {
  border-bottom: 1px solid #00000030;
}

.MemberDetailPrintTable {
  border-collapse: collapse;
}

.MemberDetailPrintTable th {
  font-size: 13px;
}

.MemberDetailPrintTable td {
  font-size: 14px;
}

/*============== member details table =========== */

.memberDetailAction {
  text-align: center;
}

.memberDetailActionDanger {
  background-color: #100d40;
  border: none;
  margin: 0px 10px;
}
.memberDetailActionDanger:hover {
  background-color: #100d4050;
  color: black;
}

.approveMemberDetailBtn {
  background-color: #100d40;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 10px;
  margin: auto;
  display: flex;
}

.table-row {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 10px 0px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: lightgray;
  display: inline-block;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.step-circle.completed {
  background-color: green;
}

.progressbarbtn1 {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f0bb31;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}
.progressbarbtn1:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.progressbarbtn2 {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #078d13;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}
.progressbarbtn2:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* ======== scrollbar edit ======== */

/* Targets the whole scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Width of vertical scrollbar */
  height: 6px; /* Height of horizontal scrollbar */
}

/* Targets the track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #e7e4e4;
  /* border-radius: 10px; */
}

/* Targets the thumb (the moving part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #9ca3af; /* Change to your preferred color */
  border-radius: 10px;
  /* border: 2px solid #f1f1f1; Optional: Adds space around thumb */
}

/* Changes color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* ========= input box styles ========= */

.input-fields {
  border: 2px solid #ececec;
  border-radius: 5px;
  padding: 5px 8px;
  accent-color: gray;
}

.input-fields,
input {
  accent-color: gray;
}

.input-fields,
input:focus {
  outline: none;
}

.report-btn {
  background-color: #202020;
  color: #e4e4e4;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 3px;
}

/* ======== login form ========= */

.wrapper {
  position: relative;
  width: 400px;
  height: 450px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(15px);
  padding: 0 30px;
  font-family: "Poppins", sans-serif;
}

.wrapper h1 {
  font-size: 2em;
  color: #fff;
  text-align: center;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  margin: 30px 0 0;
  border-bottom: 2px solid #fff;
}

.wrapper .input-box input {
  width: 100%;
  height: 50px;
  background: transparent;
  outline: none;
  border: none;
  font-size: 1em;
  color: #ffffff;
  margin-left: 2px;
}

.wrapper .input-box label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.wrapper .input-box input:focus ~ label,
.wrapper .input-box input:valid ~ label {
  top: -5px;
}

.wrapper .input-box .icon {
  position: absolute;
  right: 8px;
  color: #fff;
  font-size: 1.2em;
  line-height: 57px;
}

.wrapper .btn1 {
  width: 100%;
  height: 40px;
  background: #fff;
  outline: none;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  color: #000;
  margin-top: 30px;
}

.wrapper .input-box input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

@media (max-width: 360px) {
  .wrapper {
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0px;
  }

  .wrapper .input-box {
    width: 290px;
  }
}

.formError {
  color: red;
  width: 310px;
  padding-top: 18px;
}

.view-btn {
  background-color: #252525;
  color: #e4e4e4;
  border: none;
  padding: 3px 8px;
  font-size: 14px;
  border-radius: 4px;
}

.view-btn:hover {
  background-color: #d4d4d8;
  color: #181818;
}

/* ======== status bar ======== */

.step-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px 20px 5px;
  width: 65%;
  position: relative;
  /* border: 2px solid red; */
}

.progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  background-color: #d1d5db;
  width: 100%;
  transform: translateY(-50%);
  z-index: 0;
}

.progress {
  height: 100%;
  background-color: green;
  width: 0;
  transition: width 0.4s ease;
}

.step {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #bfc3c9;
  border-radius: 50%;

  font-weight: 500;
  color: #fff;
  transition: background-color 0.4s ease;
}

.num {
  font-size: 12px;
}

.step.active {
  background-color: green;
}

.step-label {
  position: absolute;
  top: 20px;
  font-size: 0.8em;
  text-align: center;
  color: #333;
  width: 70px;
}

@media (max-width: 500px) {
  .step-label {
    display: none;
  }
}

.prev-btn {
  border: none;
  outline: none;
  background-color: #404040;
  color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
}
.prev-btn:hover {
  background-color: #303030;
}

.creditManagerTable {
  border-collapse: collapse;
}

.creditManagerTable,
.creditManagerTable td,
.creditManagerTable th {
  border: none;
}

.creditManagerUpload {
  text-decoration: "underline";
  text-underline-offset: "5px";
  font-size: "17px";
  font-weight: "900";
  color: #181818;
}
