@font-face {
  font-family: "times new roman bold.";
  src: local("times new roman bold."),
    url("../assets/fonts/times new roman bold.ttf") format("truetype");
  font-weight: bold;
}

.member-details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.member-details-table td {
  width: 33.33%;
  text-align: left;
  padding: 0;
  border: 2px solid black;
}

.details-table td {
  width: 16.66%;
  text-align: left;
  /* padding-right: 2px;
  padding-left: 2px; */
  padding: 4px;
  border: 1.5px solid black;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.print-container {
  margin: 1rem;

  font-family: "times new roman bold";
}
.borderlineprint {
  height: 5px;
  width: 100%;
  background-color: #083f68;
  margin-top: -50px;
}

.header {
  background-color: #d7dee3;
  color: black;
  text-align: center;
  padding: 5px;
  height: 30px;
  margin-bottom: 10px;
}

.sub-header {
  background-color: #083f68;
  color: white;
  text-align: center;
  padding: 5px;
  height: 40px;
}

.label-container,
.value-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.label-container {
  border-right: 2px solid black;
  padding-right: 8px;
  padding-left: 4px;
  font-weight: bold;
  font-size: 20px;
}

.key-container {
  font-weight: bold;
  font-size: 26px;
}

.key-value-container {
  font-weight: bold;
  font-size: 26px;
}
.sanctionlabel-container {
  border-right: 2px solid black;
  padding-right: 2px;
  padding-left: 2px;
  font-weight: bold;
  font-size: 12px;
  width: 50%;
  height: 100px;
  padding: 5px;
}

.value-container {
  padding-left: 8px;
  font-size: 20px;
}

.photo-table {
  width: 100%;
  border-collapse: collapse;
}

.photo-table td {
  padding: 10px;
  text-align: center;
}

.value-container img {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin: auto;
}

.paaratext {
  color: "red";
}
.verification {
  border-right: 2px solid black;
}
.verificationPtos {
  background-color: #d7dee3;
  display: flex;
  justify-content: center;
}

.verificationdetails {
  display: flex;
  flex-direction: column;
}

.detail-row {
  display: flex;
  margin-bottom: 8px;
}

.label {
  flex: 1;
  padding-right: 10px;
  font-weight: 500;
}

.colon {
  flex: 0 0 20px;
  text-align: center;
}

.value {
  flex: 2;
  padding-left: 10px;
}

.paaratext {
  text-align: center;
  color: red;
}
.sanctionHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  word-spacing: 10px;
  margin-bottom: 50px;
  font-size: 20px;
}
.headingText {
  font-weight: 700;
  font-size: 22px;
}
.tablehead {
  height: 70px;
}
.tableheading {
  font-weight: bold;
  font-size: 16px;
}
.sanctionpara {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sanctionparaWithoutMarginBottom {
  font-size: 20px;
  margin-top: 20px;
}
.printlogo {
  width: 200px;
  height: 70px;
  object-fit: contain;
}
.printdetails {
  margin-top: 50px;
}
.phototable {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.phototableborder td {
  padding: 10px;
  border: 1px solid white;
}
.ptolabel {
  font-size: 16px;
  color: #083f68;
  font-weight: 700;
  margin-top: 20px;
}
